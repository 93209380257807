import React from 'react'
import styled from "styled-components";
import Img from "gatsby-image/withIEPolyfill"
import H1 from '../components/typography/h1'
import H3 from '../components/typography/h3'
import Wrapper from '../components/container'
import Button from '../components/button'
import P from '../components/typography/p'

const About = ({ image }) => {
    return (
        <Wrapper>
            <Flexbox>
                <Box>
                    <Img
                        fluid={image}
                        objectFit='cover'
                        objectPosition='50% 50%'
                        alt='Damian Loveless'
                        style={{ height: "100%" }}
                    />
                </Box>
                <Box>
                    <H3>What makes us the best guide on Kentucky Lake?</H3>
                    <H1>About Loveless Outdoor Adventures</H1>

                        <P>Damian spends many days per year on the water enjoying the hobbies he pursues. He loves the rush of hand fishing for big flathead catfish and using a rod and reel. It doesn’t matter if it’s bitter cold, deep-water blue cats and crappie, springtime bluegill and channel cats, or mid-summer pursuits, you can bet he will be wetting a line somewhere.  He also loves to gun the open water for diver ducks in late winter from layout boats. There are many hunting and angling opportunities in KY, and Damian doesn’t like to miss them.</P>

                    <Button buttonLabel="View all guide services" link="/guide-services" />
                </Box>
            </Flexbox>

        </Wrapper>
    );
};

const Flexbox = styled.div`
    display: flex;
`
const Box = styled.div`
    @media(min-width: 1000px) {
        width: calc(100% / 2 - 50px);
        :nth-child(1) {
            margin-right: 50px;
        }
    }
`


export default About;