import React from "react"
import Layout from "../templates/layout"
import BigHero from "../components/big-hero"
import Topography from '../components/topography-section'
import H3 from '../components/typography/h3'
import Container from '../components/container'
import Button from '../components/button'
import Card from '../components/card'
import styled from "styled-components";
import Quote from '../components/background-image-section'
import About from '../components/about'
import { graphql } from 'gatsby'


const IndexPage = ({ data }) => (
  <Layout
    title="Loveless Outdoor Adventures"
    description="Loveless Outdoor Adventures is your ultimate guide to sport fishing Kentucky Lake. We're professional bowfishers and will give you a professional bowfishing experience."
    image="images/Home.jpg"
  >
    <BigHero image={data.hero.childImageSharp.fluid} profile={data.profile.childImageSharp.fluid} />
    <Topography>
      <Container>
        <H3 textAlign="center">What adventure are you waiting on?</H3>
          <GuideServices>
            <Card link="/guide-services/bowfishing" title="Bow Fishing" image={data.bowfishing.childImageSharp.fluid} />
            <Card link="/guide-services/catfishing" title="Catfishing" image={data.catfishing.childImageSharp.fluid} />
            <Card link="/guide-services/duck-hunting" title="Duck Hunting" image={data.duckhunting.childImageSharp.fluid} />
          </GuideServices>
          <Center>
            <Button textAlign="center" buttonLabel="View All Guide Services" link="/guide-services"></Button>
          </Center>
      </Container>
    </Topography>

    <Quote image={data.quote.childImageSharp.fluid} quote="Damian will do what it takes to make his clients appreciate the sport of bowfishing. Very knowledgeable on the local lakes and rivers. All around great guy that will make your time on the water enjoyable. Get ready for some bowfishing action." author="- Matthew Schillinger (PR Manager at AMS Bowfishing)">
    </Quote>

    <Topography>
      <About image={data.about.childImageSharp.fluid} />
    </Topography>
  </Layout>

)

const GuideServices = styled.div`
  @media(min-width: 800px) {
    display: flex;
    width: 100%;
    margin-bottom: 3em;
  }
`

const Center = styled.div`
  text-align: center;
`

export const query = graphql`
query {
  hero: file(relativePath: { eq: "hero-background.png" }) {
    childImageSharp {
      fluid(maxWidth: 1500) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
  profile: file(relativePath: { eq: "profile-2.png" }) {
    childImageSharp {
      fluid(maxWidth: 660) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  bowfishing: file(relativePath: { eq: "bowfishing@2x.png" }) {
    childImageSharp {
      fluid(maxWidth: 373) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
  catfishing: file(relativePath: { eq: "catfishing@2x.png" }) {
    childImageSharp {
      fluid(maxWidth: 373) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
  duckhunting: file(relativePath: { eq: "duckhunting@2x.png" }) {
    childImageSharp {
      fluid(maxWidth: 373) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
  quote: file(relativePath: { eq: "quote.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 1500) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
  about: file(relativePath: { eq: "about.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 550) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
  metaImage: file(relativePath: { eq: "Home.jpg" }) {
    childImageSharp {
      fixed(width: 1200, height: 630) {
        ...GatsbyImageSharpFixed
      }
    }
  }
}
`

export default IndexPage
