import React from 'react'
import styled from "styled-components";
import Img from "gatsby-image/withIEPolyfill"
import H2 from '../components/typography/h2'
import { Link } from 'gatsby'

const Card = ({ image, title, link }) => {
    return (
        <StyledLink to={link}>
            <Block>
                <Img fluid={image} />
                <H2 textAlign="center">{title}</H2>
            </Block>
        </StyledLink>
    );
};

const Block = styled.div`
    background-color: white;
    border-radius: 15px;
    transition: .25s ease;
    &:hover {
        box-shadow: 0px 3px 16px rgba(0, 0, 0, .10);
        margin: -.1em 0 .1em 0;
    }
    @media (min-width: 800px) {
        padding-bottom: .75em;
    }
`

const StyledLink = styled(props => <Link {...props} />) `
    width: 100%;
    text-decoration: none;
    &:hover {
        cursor: pointer;
    }
    @media (min-width: 800px) {
        width: calc(100% / 3 - 15px);
        &:nth-child(2) {
            margin: 0 40px;
        }
    }
`;



export default Card;