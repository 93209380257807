
import React from 'react'
import styled from "styled-components";
import Img from "gatsby-image/withIEPolyfill"
import Wrapper from '../components/container'

const BackgroudndImage = ({ quote, image, author }) => {
    return (
        <Background>
            <Img
                fluid={image}
                objectFit='cover'
                objectPosition='50% 50%'
                alt='Damian Loveless at the US Open Bowfishing'
                style={{ height: "650px" }}
            />
                <Copy>
                    <Quote>{quote}<br /><br />{author}</Quote>
                </Copy>
            
        </Background>
    );
};

const Background = styled.section`
    position: relative;
`
const Copy = styled.div`
    position: absolute;
    top: 0;
    width: 90%;
    max-width: 900px;
    left: 50%;
    transform: translateX(-50%);
`
const Quote = styled.p`
    color: white;
    text-transform: uppercase;
    font-family: 'Teko';
    font-size: 2.25em;
    text-align: center;
    @media(min-width: 800px) {
        font-size: 3em;
    }
`

export default BackgroudndImage;