import React from "react";
import styled from "styled-components";
import Img from "gatsby-image/withIEPolyfill"
import H1 from "../components/typography/h1"
import H3 from "../components/typography/h3"
import Container from "../components/container"
import Button from "../components/button";


const BigHero = ({ image, profile }) => {
    return (
        <SlideImage>
            <Img
                fluid={image}
                objectFit='cover'
                objectPosition='50% 50%'
                alt='horses'
                style={{ height: "700px" }}
            />

            <Container>
                <Content>
                    <Headline>
                        <H3>For those who want more than just a fishing trip</H3>
                        <H1>Outdoor adventures that last a lifetime</H1>
                        <Button buttonLabel="View Our Guide Services" link="/guide-services" />
                    </Headline>

                    <ProfileImage> 
                        <Img fluid={profile} />
                    </ProfileImage>
                </Content>
            </Container>
                
        </SlideImage>
    );
};

const Content = styled.div`
    margin: 0 auto;
    position: relative;
    max-width: 1100px;
`

const SlideImage = styled.div`
    height: 100%;
    z-index: 0;
    position: relative;
`
const Headline = styled.div`
    position: absolute;
    top: -570px;
    @media (min-width: 800px) {
        width: 50%;
        top: -470px;
    }
    h3 {
        margin-bottom: 6px;
    }
    h1 {
        margin: 6px 0;
        line-height: 1em;
        @media (min-width: 800px) {
            font-size: 4rem;
            margin: 26px 0;
        }
    }
`
const ProfileImage = styled.div`
    position: absolute;
    bottom: 0px;
    right: 0;
    width: 100%;
    @media only screen 
    and (min-device-width : 768px) 
    and (max-device-width : 1024px) {
        width: 75%;
    }
    @media (min-width: 800px) {
        width: 60%;
    }
`

export default BigHero;